import React, { useState, useEffect } from 'react'
import { Form, Button, Row, Col, InputGroup } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus, faGripVertical, faClipboard } from '@fortawesome/free-solid-svg-icons'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TextareaAutosize from 'react-textarea-autosize';
import { v4 as uuidv4 } from 'uuid';
import Select, { components } from 'react-select'
import { customerService, introTextService } from '../../_services'

const IntroTextForm = (props) => {

	const state = props.data
	const [customers, setCustomers] = useState([])
	const [showItems, setShowItems] = useState(false)
	const [introTexts, setIntroTexts] = useState([])
    const handleChange = props.handleChange
    const handleSubmit = props.handleSubmit
    const {t} = useTranslation('common');

    useEffect(() => {
        setShowItems(true)
        if (customers.length == 0) {
            customerService.getAll().then(customers => {
                customers.sort((a, b) => a.name.localeCompare(b.name))
	            setCustomers(customers)
	            if (state.customerId === -1 && customers.length > 0) {
	                handleChange({target: {type: "select", name: "customerId", value: customers[0].id}})
	            }
	        })
        }
        introTextService.getAll().then(introTexts => {
            introTexts.sort((a, b) => a.customerName.localeCompare(b.customerName))
            setIntroTexts(introTexts)
        })
    }, [])

    function addItem(e) {
        e.preventDefault();

        var item = {
            id: uuidv4(),
            customerId: state.customerId,
            type: 'HEADER',
            text: '',
            position: state.items.length
        }

        let newItems = [...state.items, item]

        handleChange({target: {type: "object", name: "items", value: newItems}})
    }

    function removeItem(item, e) {
        e.preventDefault();

        var newItems = state.items.filter(t => t.id !== item.id)
        newItems.map((item, index) => {
            item.position = index
        })

        handleChange({target: {type: "object", name: "items", value: newItems}})
    }

    function handleItemChanges(item, e) {
        console.log('handleItemChanges')
        if (e.target.type == 'checkbox') {
            item[e.target.name] = e.target.checked
        } else {
            item[e.target.name] = e.target.value
        }
        handleChange({target: {type: "object", name: "items", value: state.items}})
    }

    const onDragEnd = result => {
        const { destination, source } = result

        if (!destination) {
            return
        }

        if (destination.index === source.index) {
            return
        }

        var item = state.items[source.index]
        state.items.splice(source.index, 1)
        state.items.splice(destination.index, 0, item)
        state.items.map((item, index) => {
            item.position = index
        })
        handleChange({target: {type: "object", name: "items", value: state.items}})
    }

    function onLanguageChange(e) {
		console.log('onLanguageChange')
		console.log(e)
		handleChange({target: {type: "object", name: "language", value: e}})
	}

	function formatLanguage(language) {
		switch (language) {
			case 'SV':
				return <div><img src="/flags/sv.png" style={{'width': '24px'}}/> {t('languages.sv')}</div>
			case 'EN':
				return <div><img src="/flags/en.png" style={{'width': '24px'}}/> {t('languages.en')}</div>
			case 'DE':
				return <div><img src="/flags/de.png" style={{'width': '24px'}}/> {t('languages.de')}</div>
			case 'RO':
				return <div><img src="/flags/ro.png" style={{'width': '24px'}}/> {t('languages.ro')}</div>
			case 'MS':
				return <div><img src="/flags/ms.png" style={{'width': '24px'}}/> {t('languages.ms')}</div>
			case 'ZH':
				return <div><img src="/flags/zh.png" style={{'width': '24px'}}/> {t('languages.zh')}</div>
			case 'PL':
				return <div><img src="/flags/pl.png" style={{'width': '24px'}}/> {t('languages.pl')}</div>
			case 'TR':
				return <div><img src="/flags/tr.png" style={{'width': '24px'}}/> {t('languages.tr')}</div>
			case 'RU':
				return <div><img src="/flags/ru.png" style={{'width': '24px'}}/> {t('languages.ru')}</div>
		}
	}

	function copyFromIntroText(event) {
		var introTextId = event.target.value
        if (introTextId != -1) {
            var introText = introTexts.find(introText => introText.id == introTextId)
	        console.log(introText)
	        if (introText) {
	            handleChange({target: {type: "string", name: "language", value: introText.language}})

	            var items = introText.items.map(item => (
	                {
	                    ...item,
	                    id: uuidv4(),
	                    customerId: state.customerId
	                }
	            ))
	            handleChange({target: {type: "object", name: "items", value: items}})
	        }
        }
	}

    return (
        <Form id="data-form" noValidate onSubmit={handleSubmit} validated={props.validated}>
            <Row>
	            <Col sm="6">
	                <Form.Group controlId="customer">
	                    <Form.Label>
		                    {t('introtext.columns.customer')} *
		                </Form.Label>
		                <Form.Control required as="select" name="customerId" value={state.customerId} onChange={handleChange}>
							{customers.map(customer => (
		                        <option key={customer.id} value={customer.id}>{customer.name}</option>
		                    ))}
		                </Form.Control>
	                </Form.Group>
	                { !state.id &&
		                <Form.Group controlId="copyfromintrotext">
		                    <Form.Label>
			                    {t('introtext.copyfromintrotext')}
			                </Form.Label>
			                <Form.Control required as="select" onChange={copyFromIntroText}>
			                    <option value={-1}>{t('introtext.chooseintrotext')}</option>
								{ introTexts.map(introText => (
			                        <option key={introText.id} value={introText.id}>{introText.customerName} {introText.language}</option>
			                    ))}
			                </Form.Control>
		                </Form.Group>
	                }
	                <Form.Group controlId="language">
	                    <Form.Label>
		                    {t('introtext.columns.language')} *
		                </Form.Label>
		                { ['SV', 'EN', 'DE', 'RO', 'MS', 'ZH', 'PL', 'TR', 'RU'].map(language => {
		                    return <Form.Check
		                        key={language}
			                    name="language"
			                    type={'radio'}
			                    id={language}
			                    value={language}
			                    label={formatLanguage(language)}
			                    onChange={handleChange}
			                    checked={state.language == language}
			                />
		                })}
		            </Form.Group>
		        </Col>
		    </Row>
		    { showItems &&
			    <Row>
			        <Col sm="12">
			            <Form.Group controlId="items">
			                <Form.Label>
			                    {t('introtextitem.label')}
			                </Form.Label>
			                <DragDropContext onDragEnd={onDragEnd}>
								<Droppable droppableId="1">
									{ provided => (
										<table className="table table-bordered table-sm">
											<thead>
							                    <tr>
							                        <th style={{width: 30}}></th>
							                        <th style={{width: '20%'}}>
							                            {t('introtextitem.columns.type')} *
							                        </th>
							                        <th style={{width: '20%', display: 'none'}}>
							                            {t('introtextitem.columns.number')}
							                        </th>
							                        <th>
							                            {t('introtextitem.columns.text')} *
							                        </th>
							                        <th style={{width: 20}}></th>
							                    </tr>
							                </thead>
											<tbody ref={provided.innerRef} {...provided.droppableProps}>
												{ state.items.map((item, index) => {
													return <Draggable key={item.id} draggableId={item.id} index={index}>
														{ provided => (
															<tr
																{...provided.draggableProps}
																ref={provided.innerRef}
															>
																<td style={{textAlign: 'center', verticalAlign: 'middle'}}>
																	<span {...provided.dragHandleProps}>
																		<FontAwesomeIcon icon={faGripVertical}/>
																	</span>
																</td>
																<td>
																	<Form.Control required as="select" name="type" value={item.type} onChange={e => handleItemChanges(item, e)}>
																		<option value="HEADER">{t('introtextitem.types.header')}</option>
																		<option value="TEXT">{t('introtextitem.types.text')}</option>
													                </Form.Control>
																</td>
																<td style={{display: 'none'}}>
																	<Form.Control type="text" placeholder={t('introtextitem.columns.number')} name="number" value={item.number} onChange={e => handleItemChanges(item, e)}/>
										                        </td>
																<td>
										                            <TextareaAutosize className="form-control" placeholder={t('introtextitem.columns.text')} name="text" value={item.text} onChange={e => handleItemChanges(item, e)}/>
										                        </td>
																<td style={{textAlign: 'center', verticalAlign: 'middle'}}>
																	<Button
															            onClick={(e) => removeItem(item, e)}
															            title={t("crudtable.add")}
															            size="sm"
															            color="info"
															            variant="outline-danger">
															            <FontAwesomeIcon icon={faTrash}/>
															        </Button>
																</td>
															</tr>
														)}
													</Draggable>
												})}
												{provided.placeholder}
											</tbody>
										</table>
									)}
								</Droppable>
			                </DragDropContext>
			                <Button
			                    onClick={addItem}
			                    title={t('introtext.additem')}
			                    color="info"
			                    variant="outline-primary">
			                    <FontAwesomeIcon icon={faPlus}/> {t('introtext.additem')}
			                </Button>
			            </Form.Group>
	                </Col>
	            </Row>
            }
        </Form>
    )
}

export default IntroTextForm