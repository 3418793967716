import React, { useState, useEffect } from 'react'
import { Form, Button, Row, Col, InputGroup } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus, faGripVertical, faClipboard } from '@fortawesome/free-solid-svg-icons'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TextareaAutosize from 'react-textarea-autosize';
import { v4 as uuidv4 } from 'uuid';
import Select, { components } from 'react-select'
import { customerService } from '../../_services'

const PrivacyPolicyForm = (props) => {

	const state = props.data
	const [customers, setCustomers] = useState([])
	const [showItems, setShowItems] = useState(false)
    const handleChange = props.handleChange
    const handleSubmit = props.handleSubmit
    const {t} = useTranslation('common');

    useEffect(() => {
        setShowItems(true)
        if (customers.length == 0) {
            customerService.getAll().then(customers => {
                customers.sort((a, b) => a.name.localeCompare(b.name))
	            setCustomers(customers)
	            if (state.customerId === -1 && customers.length > 0) {
	                handleChange({target: {type: "select", name: "customerId", value: customers[0].id}})
	            }
	        })
        }
    }, [])

    function addSection(e) {
        e.preventDefault();

        var section = {
            id: uuidv4(),
            customerId: state.customerId,
            title: '',
            text: '',
            position: state.sections.length
        }

        let newSections = [...state.sections, section]

        handleChange({target: {type: "object", name: "sections", value: newSections}})
    }

    function removeItem(section, e) {
        e.preventDefault();

        var newSections = state.sections.filter(t => t.id !== section.id)
        newSections.map((section, index) => {
            section.position = index
        })

        handleChange({target: {type: "object", name: "sections", value: newSections}})
    }

    function handleSectionChanges(section, e) {
        console.log('handleSectionChanges')
        if (e.target.type == 'checkbox') {
            section[e.target.name] = e.target.checked
        } else {
            section[e.target.name] = e.target.value
        }
        handleChange({target: {type: "object", name: "sections", value: state.sections}})
    }

    const onDragEnd = result => {
        const { destination, source } = result

        if (!destination) {
            return
        }

        if (destination.index === source.index) {
            return
        }

        var section = state.sections[source.index]
        state.sections.splice(source.index, 1)
        state.sections.splice(destination.index, 0, section)
        state.sections.map((section, index) => {
            section.position = index
        })
        handleChange({target: {type: "object", name: "sections", value: state.sections}})
    }

    function onLanguageChange(e) {
		console.log('onLanguageChange')
		console.log(e)
		handleChange({target: {type: "object", name: "language", value: e}})
	}

	function formatLanguage(language) {
		switch (language) {
			case 'SV':
				return <div><img src="/flags/sv.png" style={{'width': '24px'}}/> {t('languages.sv')}</div>
			case 'EN':
				return <div><img src="/flags/en.png" style={{'width': '24px'}}/> {t('languages.en')}</div>
			case 'DE':
				return <div><img src="/flags/de.png" style={{'width': '24px'}}/> {t('languages.de')}</div>
			case 'RO':
				return <div><img src="/flags/ro.png" style={{'width': '24px'}}/> {t('languages.ro')}</div>
			case 'MS':
				return <div><img src="/flags/ms.png" style={{'width': '24px'}}/> {t('languages.ms')}</div>
			case 'ZH':
				return <div><img src="/flags/zh.png" style={{'width': '24px'}}/> {t('languages.zh')}</div>
			case 'PL':
				return <div><img src="/flags/pl.png" style={{'width': '24px'}}/> {t('languages.pl')}</div>
			case 'TR':
				return <div><img src="/flags/tr.png" style={{'width': '24px'}}/> {t('languages.tr')}</div>
			case 'RU':
				return <div><img src="/flags/ru.png" style={{'width': '24px'}}/> {t('languages.ru')}</div>
		}
	}

	function getCode() {
		return '<a href="' + window.location.protocol + '//' + window.location.host + '/privacy-policy/?privacyPolicy=' + state.id + '" target="_blank">\n' +
				(state.language == 'SV' ? 'villkoren' : 'Privacy policy') + '\n' +
			'</a>'
	}

	function copyCode() {
        const textArea = document.createElement("textarea");
        textArea.innerText = getCode();
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        textArea.remove();
    }

    return (
        <Form id="data-form" noValidate onSubmit={handleSubmit} validated={props.validated}>
            <Row>
	            <Col sm="6">
	                { state.id &&
			            <Form.Group controlId="id">
			                <Form.Label>
			                    {t('privacypolicy.columns.code')}
			                </Form.Label>
			                <InputGroup className="mb-3">
			                    <TextareaAutosize className="form-control" value={getCode()} disabled/>
			                    <InputGroup.Append>
									<Button variant="outline-secondary" onClick={copyCode}>
										<FontAwesomeIcon icon={faClipboard}/>
									</Button>
								</InputGroup.Append>
			                </InputGroup>
			            </Form.Group>
		            }
	                <Form.Group controlId="customer">
	                    <Form.Label>
		                    {t('privacypolicy.columns.customer')} *
		                </Form.Label>
		                <Form.Control required as="select" name="customerId" value={state.customerId} onChange={handleChange}>
							{customers.map(customer => (
		                        <option key={customer.id} value={customer.id}>{customer.name}</option>
		                    ))}
		                </Form.Control>
	                </Form.Group>
	                <Form.Group controlId="name">
		                <Form.Label>
		                    {t('privacypolicy.columns.name')} *
		                </Form.Label>
		                <Form.Control required type="text" placeholder={t('privacypolicy.columns.name')} name="name" value={state.name} onChange={handleChange} />
		            </Form.Group>
	                <Form.Group controlId="language">
	                    <Form.Label>
		                    {t('privacypolicy.columns.language')} *
		                </Form.Label>
		                { ['SV', 'EN', 'DE', 'RO', 'MS', 'ZH', 'PL', 'TR', 'RU'].map(language => {
		                    return <Form.Check
		                        key={language}
			                    name="language"
			                    type={'radio'}
			                    id={language}
			                    value={language}
			                    label={formatLanguage(language)}
			                    onChange={handleChange}
			                    checked={state.language == language}
			                />
		                })}
	                </Form.Group>
		        </Col>
		    </Row>
		    { showItems &&
			    <Row>
			        <Col sm="12">
			            <Form.Group controlId="sections">
			                <Form.Label>
			                    {t('privacypolicysection.label')}
			                </Form.Label>
			                <DragDropContext onDragEnd={onDragEnd}>
								<Droppable droppableId="1">
									{ provided => (
										<table className="table table-bordered table-sm">
											<thead>
							                    <tr>
							                        <th style={{width: 30}}></th>
							                        <th>
							                            {t('privacypolicysection.columns.title')} *
							                        </th>
							                        <th>
							                            {t('privacypolicysection.columns.text')} *
							                        </th>
							                        <th style={{width: 20}}></th>
							                    </tr>
							                </thead>
											<tbody ref={provided.innerRef} {...provided.droppableProps}>
												{ state.sections.map((section, index) => {
													return <Draggable key={section.id} draggableId={section.id} index={index}>
														{ provided => (
															<tr
																{...provided.draggableProps}
																ref={provided.innerRef}
															>
																<td style={{textAlign: 'center', verticalAlign: 'middle'}}>
																	<span {...provided.dragHandleProps}>
																		<FontAwesomeIcon icon={faGripVertical}/>
																	</span>
																</td>
																<td>
																	<Form.Control required type="text" placeholder={t('privacypolicysection.columns.title')} name="title" value={section.title} onChange={e => handleSectionChanges(section, e)} />
																</td>
																<td>
										                            <TextareaAutosize className="form-control" placeholder={t('privacypolicysection.columns.text')} name="text" value={section.text} onChange={e => handleSectionChanges(section, e)}/>
										                        </td>
																<td style={{textAlign: 'center', verticalAlign: 'middle'}}>
																	<Button
															            onClick={(e) => removeItem(section, e)}
															            title={t("crudtable.add")}
															            size="sm"
															            color="info"
															            variant="outline-danger">
															            <FontAwesomeIcon icon={faTrash}/>
															        </Button>
																</td>
															</tr>
														)}
													</Draggable>
												})}
												{provided.placeholder}
											</tbody>
										</table>
									)}
								</Droppable>
			                </DragDropContext>
			                <Button
			                    onClick={addSection}
			                    title={t('privacypolicy.addsection')}
			                    color="info"
			                    variant="outline-primary">
			                    <FontAwesomeIcon icon={faPlus}/> {t('privacypolicy.addsection')}
			                </Button>
			            </Form.Group>
	                </Col>
	            </Row>
            }
        </Form>
    )
}

export default PrivacyPolicyForm