import React, {useState, useEffect} from 'react';
import CrudTable from '../table/crudtable'
import { introTextService, authService } from '../../_services'
import IntroTextForm from './introtextform.component'
import { useTranslation } from "react-i18next";

function IntroText() {

	const {t} = useTranslation('common');

	const initialState = {
		customerId: 1,
		language: 'SV',
		items: []
	}

	useEffect(() => {
    }, [])

    function formatLanguage(language) {
		switch (language) {
			case 'SV':
				return <div><img src="/flags/sv.png" style={{'width': '24px'}}/> {t('languages.sv')}</div>
			case 'EN':
				return <div><img src="/flags/en.png" style={{'width': '24px'}}/> {t('languages.en')}</div>
			case 'DE':
				return <div><img src="/flags/de.png" style={{'width': '24px'}}/> {t('languages.de')}</div>
			case 'RO':
				return <div><img src="/flags/ro.png" style={{'width': '24px'}}/> {t('languages.ro')}</div>
			case 'MS':
				return <div><img src="/flags/ms.png" style={{'width': '24px'}}/> {t('languages.ms')}</div>
			case 'ZH':
				return <div><img src="/flags/zh.png" style={{'width': '24px'}}/> {t('languages.zh')}</div>
			case 'PL':
				return <div><img src="/flags/pl.png" style={{'width': '24px'}}/> {t('languages.pl')}</div>
			case 'TR':
				return <div><img src="/flags/tr.png" style={{'width': '24px'}}/> {t('languages.tr')}</div>
			case 'RU':
				return <div><img src="/flags/ru.png" style={{'width': '24px'}}/> {t('languages.ru')}</div>
		}
		return language
	}

	const columns = [
		{
			dataField: 'customerName',
			text: t('introtext.columns.customer'),
			sort: true
		},
		{
			dataField: 'language',
			text: t('introtext.columns.language'),
			formatter: formatLanguage,
			sort: true
		}
	]

  return (
    <div>
      <h2>
        {t('introtext.label')}
      </h2>
	    <CrudTable
	        service={introTextService}
	        columns={columns}
	        initialState={initialState}
	        form={<IntroTextForm />}
	        remote={{"filter": false, "search": false, "pagination": false}}
	        sort={{dataField: 'customerName', order: 'asc'}}
	        modalSize="xl"
	        creatable={authService.hasRoles(['ROLE_INTRO_TEXT_WRITE'])}
	        editable={authService.hasRoles(['ROLE_INTRO_TEXT_WRITE'])}
	        deletable={authService.hasRoles(['ROLE_INTRO_TEXT_WRITE'])}
	      />
      </div>
  )
}

export default IntroText;