import React, { useState, useEffect } from 'react'
import { Form, Button, Row, Col, InputGroup, Tabs, Tab } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus, faGripVertical, faClipboard } from '@fortawesome/free-solid-svg-icons'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TextareaAutosize from 'react-textarea-autosize';
import { v4 as uuidv4 } from 'uuid';
import { templateService, authService } from '../../_services'
import Select, { components } from 'react-select'

const TemplateForm = (props) => {

	const state = props.data
    const [templates, setTemplates] = useState([])
    const [showItems, setShowItems] = useState(false)
    const handleChange = props.handleChange
    const handleSubmit = props.handleSubmit
    const {t} = useTranslation('common');

    useEffect(() => {
        if (!state.id) {
            templateService.getAll().then(templates => {
	            setTemplates(templates)
	        })
        }
        setShowItems(true)
    }, [])

    function addItem(e) {
        e.preventDefault();

        var item = {
            id: uuidv4(),
            customerId: state.customerId,
            type: 'TEXT_FIELD',
            required: false,
            text: {},
            options: {},
            position: state.items.length
        }

        let newItems = [...state.items, item]

        handleChange({target: {type: "object", name: "items", value: newItems}})
    }

    function removeItem(item, e) {
        e.preventDefault();

        var newItems = state.items.filter(t => t.id !== item.id)
        newItems.map((item, index) => {
            item.position = index
        })

        handleChange({target: {type: "object", name: "items", value: newItems}})
    }

    function handleItemChanges(item, e) {
        console.log('handleItemChanges')
        if (e.target.type == 'checkbox') {
            item[e.target.name] = e.target.checked
        } else {
            item[e.target.name] = e.target.value
        }
        handleChange({target: {type: "object", name: "items", value: state.items}})
    }

    function handleTranslationChange(language, e) {
        console.log('handleTranslationChange')
        var targetName = e.target.name
        var targetValue = e.target.value
        state[targetName][language] = targetValue
        handleChange({target: {type: "object", name: targetName, value: state[targetName]}})
    }

    function handleItemTranslationChange(item, language, e) {
        console.log('handleItemTranslationChange')
        var targetName = e.target.name
        item[targetName][language] = e.target.value
        handleChange({target: {type: "object", name: "items", value: state.items}})
    }

    const onDragEnd = result => {
        const { destination, source } = result

        if (!destination) {
            return
        }

        if (destination.index === source.index) {
            return
        }

        var item = state.items[source.index]
        state.items.splice(source.index, 1)
        state.items.splice(destination.index, 0, item)
        state.items.map((item, index) => {
            item.position = index
        })
        handleChange({target: {type: "object", name: "items", value: state.items}})
    }

    function onLanguageChange(e) {
		console.log('onLanguageChange')
		console.log(e)
		if (e.length > 0) {
			handleChange({target: {type: "object", name: "languages", value: e}})
		}
	}

	function formatLanguage(language) {
		switch (language) {
			case 'SV':
				return <div><img src="/flags/sv.png" style={{'width': '20px'}}/> {t('languages.sv')}</div>
			case 'EN':
				return <div><img src="/flags/en.png" style={{'width': '20px'}}/> {t('languages.en')}</div>
			case 'DE':
				return <div><img src="/flags/de.png" style={{'width': '20px'}}/> {t('languages.de')}</div>
			case 'RO':
				return <div><img src="/flags/ro.png" style={{'width': '20px'}}/> {t('languages.ro')}</div>
			case 'MS':
				return <div><img src="/flags/ms.png" style={{'width': '20px'}}/> {t('languages.ms')}</div>
			case 'ZH':
				return <div><img src="/flags/zh.png" style={{'width': '20px'}}/> {t('languages.zh')}</div>
			case 'PL':
				return <div><img src="/flags/pl.png" style={{'width': '20px'}}/> {t('languages.pl')}</div>
			case 'TR':
				return <div><img src="/flags/tr.png" style={{'width': '20px'}}/> {t('languages.tr')}</div>
			case 'RU':
				return <div><img src="/flags/ru.png" style={{'width': '20px'}}/> {t('languages.ru')}</div>
		}
	}

    function copyFromTemplate(event) {
        var templateId = event.target.value
        if (templateId != -1) {
            var template = templates.find(template => template.id == templateId)
	        console.log(template)
	        if (template) {
	            handleChange({target: {type: "object", name: "languages", value: template.languages}})
	            handleChange({target: {type: "object", name: "recaptcha", value: template.recaptcha}})
	            handleChange({target: {type: "object", name: "name", value: template.name}})
	            handleChange({target: {type: "object", name: "description", value: template.description}})
	            handleChange({target: {type: "object", name: "header", value: template.header}})
	            handleChange({target: {type: "object", name: "footer", value: template.footer}})

	            var items = template.items.map(item => (
	                {
	                    ...item,
	                    id: uuidv4(),
	                    customerId: state.customerId
	                }
	            ))
	            handleChange({target: {type: "object", name: "items", value: items}})
	        }
        }
    }

    return (
        <Form id="data-form" noValidate onSubmit={handleSubmit} validated={props.validated}>
            <Row>
	            <Col sm="6">
	                { !state.id &&
		                <Form.Group controlId="copyfromtemplate">
		                    <Form.Label>
			                    {t('form.copyfromtemplate')}
			                </Form.Label>
			                <Form.Control required as="select" onChange={copyFromTemplate}>
			                    <option value={-1}>{t('form.choosetemplate')}</option>
								{ templates.map(template => (
			                        <option key={template.id} value={template.id}>{template.name}</option>
			                    ))}
			                </Form.Control>
		                </Form.Group>
	                }
	                <Form.Group controlId="languages">
	                    <Form.Label>
		                    {t('form.columns.languages')} *
		                </Form.Label>
		                <Select
							placeholder={t('form.columns.languages')}
							options={['SV', 'EN', 'DE', 'RO', 'MS', 'ZH', 'PL', 'TR', 'RU']}
							value={state.languages}
							getOptionLabel={language => formatLanguage(language)}
							getOptionValue={language => language}
							isMulti={true}
							closeMenuOnSelect={false}
							onChange={onLanguageChange}/>
	                </Form.Group>
	                <Form.Group controlId="recaptcha">
						<Form.Check type="checkbox" label={t('form.columns.recaptcha')} name="recaptcha" checked={state.recaptcha} onChange={handleChange} />
		            </Form.Group>
		        </Col>
		    </Row>
		    <Row>
		        <Col sm="6">
                    <Form.Group controlId="name">
		                <Form.Label>
		                    {t('form.columns.name')} *
		                </Form.Label>
		                <Form.Control required type="text" placeholder={t('form.columns.name')} name="name" value={state.name} onChange={handleChange} />
		            </Form.Group>
		        </Col>
		        <Col sm="6">
		            <Form.Group controlId="description">
		                <Form.Label>
		                    {t('form.columns.description')}
		                </Form.Label>
		                <TextareaAutosize className="form-control" placeholder={t('form.columns.description')} minRows={5} name="description" value={state.description || ''} onChange={handleChange}/>
		            </Form.Group>
		        </Col>
		    </Row>
		    <Row>
		        { state.languages.map((language, index) => {
			        return <Col sm="6" key={'header-' + language + '-' + index}>
			            <Form.Group controlId="header">
			                <Form.Label>
			                    {t('form.columns.header')} {language}
			                </Form.Label>
			                <TextareaAutosize className="form-control" placeholder={t('form.columns.header')} minRows={5} name="header" value={state.header[language]} onChange={e => handleTranslationChange(language, e)}/>
			            </Form.Group>
			        </Col>
		        })}
		    </Row>
		    <Row>
		        { state.languages.map((language, index) => {
			        return <Col sm="6" key={'footer-' + language + '-' + index}>
			            <Form.Group controlId="footer">
			                <Form.Label>
			                    {t('form.columns.footer')} {language}
			                </Form.Label>
			                <TextareaAutosize className="form-control" placeholder={t('form.columns.footer')} minRows={5} name="footer" value={state.footer[language]} onChange={e => handleTranslationChange(language, e)}/>
			            </Form.Group>
			        </Col>
		        })}
		    </Row>
		    { showItems &&
			    <Row>
			        <Col sm="12">
			            <Form.Group controlId="items">
			                <Form.Label>
			                    {t('item.label')}
			                </Form.Label>
			                <Tabs defaultActiveKey={state.languages[0]} id="uncontrolled-tab-example" className="mb-3" mountOnEnter={true}>
			                    { state.languages.map((language, index) => {
									return <Tab key={language} eventKey={language} title={formatLanguage(language)}>
						                <DragDropContext onDragEnd={onDragEnd}>
											<Droppable droppableId="1">
												{ provided => (
													<table className="table table-bordered table-sm">
														<thead>
										                    <tr>
										                        <th style={{width: 30}}></th>
										                        <th>
										                            {t('item.columns.type')} *
										                        </th>
									                            <th>
										                            {t('item.columns.text')}
										                        </th>
									                           <th>
									                                {t('item.columns.options')}
									                            </th>
										                        <th>
										                            {t('item.columns.required')}
										                        </th>
										                        <th style={{width: 20}}></th>
										                    </tr>
										                </thead>
														<tbody ref={provided.innerRef} {...provided.droppableProps}>
															{ state.items.map((item, index) => {
																return <Draggable key={item.id} draggableId={item.id} index={index}>
																	{ provided => (
																		<tr
																			{...provided.draggableProps}
																			ref={provided.innerRef}
																		>
																			<td style={{textAlign: 'center', verticalAlign: 'middle'}}>
																				<span {...provided.dragHandleProps}>
																					<FontAwesomeIcon icon={faGripVertical}/>
																				</span>
																			</td>
																			<td>
																				<Form.Control required as="select" name="type" value={item.type} onChange={e => handleItemChanges(item, e)}>
																					<option value="TEXT_FIELD">{t('item.types.textfield')}</option>
																					<option value="TEXT_AREA">{t('item.types.textarea')}</option>
																					<option value="RADIO_BUTTONS">{t('item.types.radiobuttons')}</option>
																					<option value="CHECKBOX">{t('item.types.checkbox')}</option>
																					<option value="CHECKBOXES">{t('item.types.checkboxes')}</option>
																					<option value="DROPDOWN">{t('item.types.dropdown')}</option>
																					<option value="MAP">{t('item.types.map')}</option>
																					<option value="FILE">{t('item.types.file')}</option>
																					<option value="TEXT">{t('item.types.text')}</option>
																					<option value="HEADER">{t('item.types.header')}</option>
																                </Form.Control>
																			</td>
												                            <td>
													                            <TextareaAutosize className="form-control" placeholder={t('item.columns.text')} name="text" value={item.text[language]} onChange={e => handleItemTranslationChange(item, language, e)}/>
													                        </td>
												                            <td>
													                            { (item.type == 'RADIO_BUTTONS' || item.type == 'DROPDOWN' || item.type == 'CHECKBOXES') &&
																					<TextareaAutosize className="form-control" placeholder={t('item.columns.options')} name="options" value={item.options[language]} onChange={e => handleItemTranslationChange(item, language, e)}/>
																				}
												                            </td>
																			<td>
																				<Form.Group controlId={'required-' + item.id}>
																	                <Form.Check type="checkbox" name="required" checked={item.required} onChange={e => handleItemChanges(item, e)} />
																	            </Form.Group>
																            </td>
																			<td style={{textAlign: 'center', verticalAlign: 'middle'}}>
																				<Button
																		            onClick={(e) => removeItem(item, e)}
																		            title={t("crudtable.add")}
																		            size="sm"
																		            color="info"
																		            variant="outline-danger">
																		            <FontAwesomeIcon icon={faTrash}/>
																		        </Button>
																			</td>
																		</tr>
																	)}
																</Draggable>
															})}
															{provided.placeholder}
														</tbody>
													</table>
												)}
											</Droppable>
						                </DragDropContext>
						                <Button
						                    onClick={addItem}
						                    title={t('form.additem')}
						                    color="info"
						                    variant="outline-primary">
						                    <FontAwesomeIcon icon={faPlus}/> {t('form.additem')}
						                </Button>
						            </Tab>
								})}
							</Tabs>
			            </Form.Group>
	                </Col>
	            </Row>
	        }
        </Form>
    )
}

export default TemplateForm